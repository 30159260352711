import React from "react"
import { useEffect, useState } from "react";
import getNowPlayingItem from "./spotifyapi"

function SpotifyPlayer(props) {
  const [result, setResult] = useState({});

  useEffect(() => {
    Promise.all([
      getNowPlayingItem(
        props.client_id,
        props.client_secret,
        props.refresh_token
      ),
    ]).then((results) => {
      setResult(results[0]);
    });
  }, []);

  return result.isPlaying ? (
    <p className="playdisplay">
          <div className="wrap">
      <div className="cube">
        <div className="front"><img src={result.albumImageUrl}/></div>
        <div className="back"><img src={result.albumImageUrl}/></div>
        <div className="top"><img src={result.albumImageUrl}/></div>
        <div className="bottom"><img src={result.albumImageUrl}/></div>
        <div className="left"><img src={result.albumImageUrl}/></div>
        <div className="right"><img src={result.albumImageUrl}/></div>
      </div>
    </div><br/><br/>
      {/* <img src={result.albumImageUrl}/><img src={result.albumImageUrl}/><img src={result.albumImageUrl}/><br/><br/> */}
      <i class="fab fa-spotify" style={{color: "#FFF"}}></i> <a href={result.songUrl} target="_blank">{result.title}</a><br/>by {result.artist}<br/>
    </p>
  ) : (
    <p className="playdisplay"></p>
  );
}

export default SpotifyPlayer;