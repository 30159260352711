import React from "react"
import "../styles/index.css"
import { Helmet } from "react-helmet"
import NowPlayingDisplay from "../components/nowplayingdisplay"

export default () => (
  <div>
    <Helmet>
      <meta charSet="utf-8" name="robots" content="noindex" name="theme-color" content="#000" />
      <title>NOW PLAYING 〜 🌴 gs / xyz</title>
      <link rel="canonical" href="https://gagansingh.xyz" />
      <script src="https://kit.fontawesome.com/6b889176de.js" crossorigin="anonymous"></script>
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    </Helmet>
    <div className="np-display">
    <NowPlayingDisplay />
    </div>
    <div className="top-bar"><a href="/">☺</a></div>
  </div>
)
