import React from "react";
import SpotifyPlayer from "./spotifydisplay";

function NowPlayingDisplay() {
     return (
          <SpotifyPlayer
               client_id={process.env.GATSBY_SPOTIFY_CLIENT_ID}
               client_secret={process.env.GATSBY_SPOTIFY_CLIENT_SECRET}
               refresh_token={process.env.GATSBY_SPOTIFY_REFRESH_TOKEN}
               />
     );
}

export default NowPlayingDisplay;